/**
 * Confirm.jsx
 *
 * @file This component gives the user the ability to confirm his/her
 * participation to an event.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { gql, useMutation, useQuery } from '@apollo/client'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import logging from 'loglevel'
import moment from 'moment'
import NProgress from 'nprogress'
import qs from 'qs'
import React, { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useLocation, useParams } from '@reach/router'
import validator from 'validator'

// internal imports
import { Link } from '../../Link'
import routes from '../../../data/routes.json'
import { transformAppellation, transformParticipantType } from '../../../utils'

/** Mutation to mark the participation as confirmed on the server. */
const CONFIRM_PARTICIPANT = gql`
	mutation confirmParticipant( $id: ID! $uuid: ID! ) {
		confirmParticipant( id: $id uuid: $uuid ) {
			id
		}
	}
`

/** Query to fetch the participant from the server. */
const GET_PARTICIPANT = gql`
	query getParticipant( $uuid: ID! ) {
		participantByUUID( uuid: $uuid ) {
			appellation
			email
			firstName
			id
			lastName
			participation {
				event {
					eventID
					id
					venue {
						city
						id
					}
				}
				id
			}
			type
			uuid
		}
	}
`

/**
 * Give the user the ability to confirm his/her participation.
 *
 * @returns {Node} The rendered component.
 */
const Confirm = () => {

	const location  = useLocation()
	const signature = qs.parse( location.search, { ignoreQueryPrefix: true } )
	const params    = useParams()

	/** Define a state holding the decision of the user to confirm his/her participation. */
	const [ needToConfirm, setNeedToConfirmed ] = useState( true )

	/** Fetch the participation from the GraphQL Server. */
	const { data: queryData, error: queryError, loading: queryLoading } = useQuery( GET_PARTICIPANT, {
		context: {
			uri: process.env.GATSBY_APOLLO_CLIENT_ENDPOINT + location.search + '&route=confirm'
		},
		displayName: 'GetParticipantByUUIDQuery',
		ssr: false,
		variables: {
			uuid: params.uuid
		}
	} )

	/** Confirm the `Participation` on the server. */
	const [ confirmParticipant, { data: mutationData, error: mutationError, loading: mutationLoading } ] = useMutation( CONFIRM_PARTICIPANT, {
		context: {
			uri: process.env.GATSBY_APOLLO_CLIENT_ENDPOINT + location.search + '&route=confirm'
		},
		onCompleted: () => {
			setNeedToConfirmed( false )
		}
	} )

	if ( typeof params.uuid === 'undefined' || !validator.isUUID( params.uuid ) )
		return (
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '100%',
					mb: 2,
					textAlign: 'center',
					width: '100%',
				}}>
				<Typography>Es trat ein unerwarteter Fehler auf.</Typography>
			</Box>
		)

	if ( typeof signature.expires === 'undefined' || typeof signature.signature === 'undefined' )
		return (
			<>
				<GatsbySeo title="Bestätigungslink ungültig" />
				<Typography align="center" color="primary" gutterBottom variant="h4">Bestätigungslink ungültig</Typography>

				<Typography paragraph>Mit dem Link scheint etwas nicht in Ordnung zu sein!</Typography>
				<Typography paragraph variant="body2">Der Link beinhaltet nicht alle benötigten Parameter. Manche E-Mail Programme maskieren Links in E-Mails, was dazu geführt haben könnte, dass diser Link defekt ist. Bitte kopieren Sie den oberen Bestätigungslink von ganz unten aus der E-Mail und fügen diesen manuell in die Adresszeile Ihres Browsers ein.</Typography>
				<Typography paragraph variant="body2">Wenn Sie den Link manuell kopieren und in die Adresszeile Ihres Browsers einfügen, achten Sie darauf, dass Sie den Link wirklich vollständig kopieren und keine Zeichen am Anfang oder Ende fehlen.</Typography>
			</>
		)

	const expires = moment.unix( signature.expires )
	const now = moment()

	if ( !now.isBefore( expires ) )
		return (
			<>
				<GatsbySeo title="Anmeldung abgelaufen" />
				<Typography align="center" color="primary" gutterBottom variant="h4">Anmeldung abgelaufen</Typography>

				<Typography paragraph>Ihre Anmeldung konnte nicht in der Datenbank gefunden werden!</Typography>
				<Typography paragraph variant="body2">Dies ist darauf zurückzuführen, dass der Bestätigungslink aus der E-Mail seit dem { expires.format( 'DD. MMMM YYYY' ) } abgelaufen zu sein scheint und Ihre personenbezogenen Daten automatisch aus unserem System entfernt wurden.</Typography>
				<Typography paragraph variant="body2">Bitte beachten Sie, dass der Bestätigungslink nur 14 Tage gültig war.</Typography>
				<Typography paragraph variant="body2">Sollten Sie weiterhin Interesse an der Teilnahme einer unserer Schulungen haben, sprechen Sie bitte Ihre(n) zuständige(n) Schulkoordinator(in) darauf an und bitten ihn/sie, Sie erneut für eine Veranstaltung anzumelden.</Typography>
			</>
		)

	if ( queryLoading ) {
		NProgress.start()

		return (
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '100%',
					mb: 2,
					textAlign: 'center',
					width: '100%',
				}}>
				<CircularProgress
					size={ 50 }
					sx={{ color: 'secondary.main' }} />
			</Box>
		)
	}
	else if ( mutationLoading )
		NProgress.start()

	else NProgress.done()

	if ( queryError )
		return (
			<>
				<GatsbySeo title="Keine gültige Anmeldung" />
				<Typography align="center" color="primary" gutterBottom variant="h4">Keine gültige Anmeldung</Typography>

				<Typography paragraph>Ihre Anmeldung konnte nicht in der Datenbank gefunden werden!</Typography>
				<Typography paragraph variant="body2">Hierfür könnte es verschiedene Gründe geben:</Typography>

				<List aria-label="Mögliche Ursachen">
					<ListItem>
						<ListItemText primaryTypographyProps={ { variant: 'body2' } }><strong>Der Link wurde evtl. von Ihrem E-Mail Programm maskiert.</strong> Um sicherzugehen, dass der Link nicht "falsch" an den Browser übergeben wurde, kopieren Sie ihn und fügen ihn manuell in die Adresszeile Ihres Browsers ein.</ListItemText>
					</ListItem>
					<ListItem>
						<ListItemText primaryTypographyProps={ { variant: 'body2' } }><strong>Ihre Anmeldung wurde möglicherweise bereits aus unserem System gelöscht.</strong> Ihre Anmeldung bleibt nur für 30 Tage in unserem System und wird dann automatisch gelöscht, falls Sie sie nicht bestätigen.</ListItemText>
					</ListItem>
					<ListItem>
						<ListItemText primaryTypographyProps={ { variant: 'body2' } }><strong>Haben Sie Ihre Anmeldung bereits ausversehen widerrufen?</strong> Sollte dies der Fall sein, könnte dies ebenfalls eine Ursache dafür sein, dass Ihre Anmeldung bereits aus unserem System gelöscht wurde.</ListItemText>
					</ListItem>
				</List>

				<Typography paragraph variant="body2">Könnten Ihrer Auffassung nach keine der genannten Möglichkeiten Grund für diese Fehlermeldung sein, wenden Sie sich bitte über unser <Link to={ routes[ 4 ].path }>Kontaktformular</Link> an uns und erläutern Sie uns Ihr Problem. Bitte fügen Sie Ihrem Schreiben die Veranstaltungsnummer, den Bestätigungslink und auch Ihre Daten bei, damit wir der Ursache auf den Grund gehen können und prüfen können, ob Ihre Anmeldung noch in unserem System ist und wir sie gegebenfalls manuell bestätigen können.</Typography>
				<Typography paragraph variant="body2">Vielen Dank für Ihr Verständnis!</Typography>
			</>
		)

	return (
		<>
			<GatsbySeo title="Bestätigen Sie Ihre Anmeldung" />
			<Typography align="center" color="primary" gutterBottom variant="h4">Bestätigen Sie Ihre Anmeldung</Typography>

			<Toolbar sx={{ mt: 2, pl: 2, pr: 1 }}>
				<Typography variant="h6" component="div" sx={{ color: 'primary.main', flex: '1 1 100%' }}>Sie wurden für folgende Veranstaltung angemeldet:</Typography>
			</Toolbar>
			<TableContainer component="div">
				<Table aria-label="Die Veranstaltung" sx={{ minWidth: 650 }}>
					<TableHead>
						<TableRow>
							<TableCell>#ID</TableCell>
							<TableCell>Veranstaltungsnummer</TableCell>
							<TableCell>Veranstaltungsort</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell sx={{ borderBottom: 0 }}>{ queryData.participantByUUID.participation.event.id }</TableCell>
							<TableCell sx={{ borderBottom: 0 }}>{ queryData.participantByUUID.participation.event.eventID }</TableCell>
							{ !_.isEmpty( queryData.participantByUUID.participation.event.venue ) && <TableCell sx={{ borderBottom: 0 }}>{ queryData.participantByUUID.participation.event.venue.city }</TableCell> }
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>

			<Toolbar sx={{ mt: 2, pl: 2, pr: 1 }}>
				<Typography variant="h6" component="div" sx={{ color: 'primary.main', flex: '1 1 100%' }}>Ihre Daten</Typography>
			</Toolbar>
			<TableContainer component="div">
				<Table aria-label="Ihre Daten" sx={{ minWidth: 650 }}>
					<TableHead>
						<TableRow>
							<TableCell>#ID</TableCell>
							<TableCell>Anrede</TableCell>
							<TableCell>Vorname</TableCell>
							<TableCell>Nachname</TableCell>
							<TableCell>E-Mail Adresse</TableCell>
							<TableCell>Personengruppe</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell sx={{ borderBottom: 0 }}>{ queryData.participantByUUID.uuid }</TableCell>
							<TableCell sx={{ borderBottom: 0 }}>{ transformAppellation( queryData.participantByUUID.appellation ) }</TableCell>
							<TableCell sx={{ borderBottom: 0 }}>{ queryData.participantByUUID.firstName }</TableCell>
							<TableCell sx={{ borderBottom: 0 }}>{ queryData.participantByUUID.lastName }</TableCell>
							<TableCell sx={{ borderBottom: 0 }}>{ queryData.participantByUUID.email }</TableCell>
							<TableCell sx={{ borderBottom: 0 }}>{ transformParticipantType( queryData.participantByUUID.type ) }</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>

			<Button
				color="secondary"
				disabled={ !needToConfirm }
				onClick={ () => {
					confirmParticipant( { variables: { id: queryData.participantByUUID.id, uuid: params.uuid } } )
				} }
				variant="contained">
				Anmeldung bestätigen
			</Button>

			{ !needToConfirm && <Typography gutterBottom variant="h5">Ihre Anmeldung wurde erfolgreich bestätigt!</Typography> }
		</>
	)
}

export default Confirm
