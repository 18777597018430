/**
 * participants.jsx
 *
 * @file Participants page of the web application.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { GatsbySeo } from 'gatsby-plugin-next-seo'
import React from 'react'
import { Router } from '@reach/router'

// internal imports
import { ParticipantsConfirm, ParticipantsOptOut } from '../components/Participants'

/**
 * This component represents the Participants page of this application.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const ParticipantsPage = () => {

	return (
		<>
			<GatsbySeo title="Participants" />
			<Router basepath="/participants">
				<ParticipantsConfirm path="/:uuid/confirm" />
				<ParticipantsOptOut path="/:uuid/opt-out" />
			</Router>
		</>
	)
}

export default ParticipantsPage
