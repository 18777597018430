/**
 * OptOut.jsx
 *
 * @file This component gives the user the ability to deny his/her
 * participation to an event.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { gql, useMutation, useQuery } from '@apollo/client'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import logging from 'loglevel'
import NProgress from 'nprogress'
import qs from 'qs'
import React, { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useLocation, useParams } from '@reach/router'
import validator from 'validator'

// internal imports
import { Link } from '../../Link'
import routes from '../../../data/routes.json'
import { transformAppellation, transformParticipantType } from '../../../utils'

/** Query to fetch the participant from the server. */
const GET_PARTICIPANT = gql`
	query getParticipant( $uuid: ID! ) {
		participantByUUID( uuid: $uuid ) {
			appellation
			email
			firstName
			id
			lastName
			participation {
				event {
					eventID
					id
					venue {
						city
						id
					}
				}
				id
			}
			type
			uuid
		}
	}
`

/** Mutation to set the status of the participant as "ABGEMELDET" on the server. */
const OPT_OUT_PARTICIPANT = gql`
	mutation optOutParticipant( $id: ID! $status: ParticipantStatus! ) {
		updateParticipantStatus( id: $id status: $status ) {
			id
		}
	}
`

/**
 * Give the user the ability to deny his/her participation.
 *
 * @returns {Node} The rendered component.
 */
const OptOut = () => {

	const location  = useLocation()
	const signature = qs.parse( location.search, { ignoreQueryPrefix: true } )
	const params    = useParams()

	/** Define a state holding the decision of the user to opt-out of his/her participation. */
	const [ needToOptOut, setNeedToOptOut ] = useState( true )

	/** Fetch the participation from the GraphQL Server. */
	const { data: queryData, error: queryError, loading: queryLoading } = useQuery( GET_PARTICIPANT, {
		context: {
			uri: process.env.GATSBY_APOLLO_CLIENT_ENDPOINT + location.search + '&route=opt-out'
		},
		displayName: 'GetParticipantByUUIDQuery',
		ssr: false,
		variables: {
			uuid: params.uuid
		}
	} )

	/** Confirm the `Participation` on the server. */
	const [ optOutParticipant, { data: mutationData, error: mutationError, loading: mutationLoading } ] = useMutation( OPT_OUT_PARTICIPANT, {
		onCompleted: () => {
			setNeedToOptOut( false )
		}
	} )

	if ( typeof params.uuid === 'undefined' || !validator.isUUID( params.uuid ) )
		return (
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '100%',
					mb: 2,
					textAlign: 'center',
					width: '100%',
				}}>
				<Typography>Es trat ein unerwarteter Fehler auf.</Typography>
			</Box>
		)

	if ( typeof signature.signature === 'undefined' )
		return (
			<>
				<GatsbySeo title="Widerrufslink ungültig" />
				<Typography align="center" color="primary" gutterBottom variant="h4">Widerrufslink ungültig</Typography>

				<Typography paragraph>Mit dem Link scheint etwas nicht in Ordnung zu sein!</Typography>
				<Typography paragraph variant="body2">Der Link beinhaltet nicht alle benötigten Parameter. Manche E-Mail Programme maskieren Links in E-Mails, was dazu geführt haben könnte, dass diser Link defekt ist. Bitte kopieren Sie den unteren Widerrufslink von ganz unten aus der E-Mail und fügen diesen manuell in die Adresszeile Ihres Browsers ein.</Typography>
				<Typography paragraph variant="body2">Wenn Sie den Link manuell kopieren und in die Adresszeile Ihres Browsers einfügen, achten Sie darauf, dass Sie den Link wirklich vollständig kopieren und keine Zeichen am Anfang oder Ende fehlen.</Typography>
			</>
		)

	if ( queryLoading ) {
		NProgress.start()

		return (
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '100%',
					mb: 2,
					textAlign: 'center',
					width: '100%',
				}}>
				<CircularProgress
					size={ 50 }
					sx={{ color: 'secondary.main' }} />
			</Box>
		)
	}
	else if ( mutationLoading )
		NProgress.start()

	else
		NProgress.done()

	if ( queryError )
		return (
			<>
				<GatsbySeo title="Keine gültige Anmeldung" />
				<Typography align="center" color="primary" gutterBottom variant="h4">Keine gültige Anmeldung</Typography>

				<Typography paragraph>Ihre Anmeldung konnte nicht in der Datenbank gefunden werden!</Typography>
				<Typography paragraph variant="body2">Hierfür könnte es verschiedene Gründe geben:</Typography>

				<List aria-label="Mögliche Ursachen">
					<ListItem>
						<ListItemText primaryTypographyProps={ { variant: 'body2' } }><strong>Der Link wurde evtl. von Ihrem E-Mail Programm maskiert.</strong> Um sicherzugehen, dass der Link nicht "falsch" an den Browser übergeben wurde, kopieren Sie ihn und fügen ihn manuell in die Adresszeile Ihres Browsers ein.</ListItemText>
					</ListItem>
					<ListItem>
						<ListItemText primaryTypographyProps={ { variant: 'body2' } }><strong>Ihre Anmeldung wurde möglicherweise bereits aus unserem System gelöscht.</strong> Ihre Anmeldung bleibt nur für 30 Tage in unserem System und wird dann automatisch gelöscht, falls Sie sie nicht bestätigen.</ListItemText>
					</ListItem>
					<ListItem>
						<ListItemText primaryTypographyProps={ { variant: 'body2' } }><strong>Sie haben Ihre Anmeldung bereits widerrufen.</strong> Sollte dies der Fall sein, könnte dies ebenfalls eine Ursache dafür sein, dass Ihre Anmeldung bereits aus unserem System gelöscht wurde.</ListItemText>
					</ListItem>
				</List>

				<Typography paragraph variant="body2">Könnten Ihrer Auffassung nach keine der genannten Möglichkeiten Grund für diese Fehlermeldung sein, wenden Sie sich bitte über unser <Link to={ routes[ 4 ].path }>Kontaktformular</Link> an uns und erläutern Sie uns Ihr Problem. Bitte fügen Sie Ihrem Schreiben die Veranstaltungsnummer, den Bestätigungslink und auch Ihre Daten bei, damit wir der Ursache auf den Grund gehen können und prüfen können, ob Ihre Anmeldung noch in unserem System ist und wir sie gegebenfalls manuell widerrufen können.</Typography>
				<Typography paragraph variant="body2">Vielen Dank für Ihr Verständnis!</Typography>
			</>
		)

	return (
		<>
			<GatsbySeo title="Widerrufen Sie Ihre Anmeldung" />
			<Typography align="center" color="primary" gutterBottom variant="h4">Widerrufen Sie Ihre Anmeldung</Typography>

			<Toolbar sx={{ mt: 2, pl: 2, pr: 1 }}>
				<Typography variant="h6" component="div" sx={{ color: 'primary.main', flex: '1 1 100%' }}>Sie wurden für folgende Veranstaltung angemeldet:</Typography>
			</Toolbar>
			<TableContainer component="div">
				<Table aria-label="Die Veranstaltung" sx={{ minWidth: 650 }}>
					<TableHead>
						<TableRow>
							<TableCell>#ID</TableCell>
							<TableCell>Veranstaltungsnummer</TableCell>
							<TableCell>Veranstaltungsort</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell sx={{ borderBottom: 0 }}>{ queryData.participantByUUID.participation.event.id }</TableCell>
							<TableCell sx={{ borderBottom: 0 }}>{ queryData.participantByUUID.participation.event.eventID }</TableCell>
							{ !_.isEmpty( queryData.participantByUUID.participation.event.venue ) && <TableCell sx={{ borderBottom: 0 }}>{ queryData.participantByUUID.participation.event.venue.city }</TableCell> }
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>

			<Toolbar sx={{ mt: 2, pl: 2, pr: 1 }}>
				<Typography variant="h6" component="div" sx={{ color: 'primary.main', flex: '1 1 100%' }}>Ihre Daten</Typography>
			</Toolbar>
			<TableContainer component="div">
				<Table aria-label="Ihre Daten" sx={{ minWidth: 650 }}>
					<TableHead>
						<TableRow>
							<TableCell>#ID</TableCell>
							<TableCell>Anrede</TableCell>
							<TableCell>Vorname</TableCell>
							<TableCell>Nachname</TableCell>
							<TableCell>E-Mail Adresse</TableCell>
							<TableCell>Personengruppe</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell sx={{ borderBottom: 0 }}>{ queryData.participantByUUID.uuid }</TableCell>
							<TableCell sx={{ borderBottom: 0 }}>{ transformAppellation( queryData.participantByUUID.appellation ) }</TableCell>
							<TableCell sx={{ borderBottom: 0 }}>{ queryData.participantByUUID.firstName }</TableCell>
							<TableCell sx={{ borderBottom: 0 }}>{ queryData.participantByUUID.lastName }</TableCell>
							<TableCell sx={{ borderBottom: 0 }}>{ queryData.participantByUUID.email }</TableCell>
							<TableCell sx={{ borderBottom: 0 }}>{ transformParticipantType( queryData.participantByUUID.type ) }</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>

			<Button
				color="secondary"
				disabled={ !needToOptOut }
				onClick={ () => {
					optOutParticipant( { variables: { id: queryData.participantByUUID.id, status: 'ABGEMELDET' } } )
				} }
				variant="contained">
				Anmeldung widerrufen
			</Button>

			{ !needToOptOut && <Typography gutterBottom variant="h5">Ihre Anmeldung wurde erfolgreich widerrufen!</Typography> }
		</>
	)

}

export default OptOut
